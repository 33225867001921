import {
    Flex,
    useMultiStyleConfig
} from '@salesforce/retail-react-app/app/components/shared/ui'
import PropTypes from 'prop-types'
import React from 'react'
import {styleConfigChakraKeys} from '../../constants'
import SliderProgressChip from './slider-progress-chip'

export const SliderProgress = ({
    totalTiles,
    activeIndex,
    moveByChip,
    variant = 'flat',
    chipColor = '',
    ...otherProps
}) => {
    const styles = useMultiStyleConfig(styleConfigChakraKeys.sliderProgress, {
        variant
    })
    const handleKeyDown = (event, index) => {
        // "Enter" or "Space" key
        if (event.keyCode === 13 || event.keyCode === 32) {
            moveByChip(index)
        }
    }
    return (
        <Flex
           {...styles.chipContainer}
           display={['flex', 'flex', 'flex', 'none']}
           {...otherProps}
        >
            {Array.from({length: totalTiles}).map((_, index) => (
                <SliderProgressChip
                    key={index}
                    index={index}
                    moveByChip={moveByChip}
                    handleKeyDown={handleKeyDown}
                    variant={variant}
                    selected={index === activeIndex}
                    chipColor={chipColor}
                />
            ))}
        </Flex>
    )
}

SliderProgress.displayName = 'SliderProgress'

SliderProgress.propTypes = {
    totalTiles: PropTypes.any,
    activeIndex: PropTypes.any,
    moveByChip: PropTypes.func,
    variant: PropTypes.oneOf(['circle', 'flat']),
    chipColor: PropTypes.string
}

export default SliderProgress
